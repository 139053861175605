import React from 'react';
import { withStyles } from '@material-ui/core';
import * as Sentry from '@sentry/react';

import ErrorState from 'src/components/ErrorState';
import AuthContext from 'src/components/AuthProvider/context';

const useStyles = () => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 200px)',
  },
});

const updateAction = {
  content: 'Refresh',
  onAction: () => window.location.reload(),
};

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static contextType = AuthContext;

  errorAction = {
    content: 'Send Error Report',
    onAction: () =>
      Sentry.showReportDialog({
        eventId: this.state.eventId,
        user: {
          email: this.context?.user.email,
          name: this.context?.user.fullName,
        },
      }),
  };

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, info });
    });
    this.setState({
      hasError: true,
      error,
    });
  }

  getDefaultMessage() {
    // FIXME: This is a quick fix based on the reported errors.
    if (!Object.fromEntries) {
      return 'Your browser appears to be outdated. Please consider updating it to the latest version.';
    }

    if (!navigator?.onLine)
      return 'Your internet connection appears to be offline. Please check your connection, then reload the page.';

    return 'Something went wrong.';
  }

  isChunkLoadError() {
    const { error } = this.state;

    if (!navigator?.onLine) return false;

    return (
      error?.name === 'ChunkLoadError' ||
      error?.message?.match(/Loading (?:CSS )?chunk (\d+) failed\./)
    );
  }

  render() {
    const { hasError } = this.state;
    const { classes } = this.props;

    if (hasError) {
      return (
        <div className={this.props.inline ? '' : classes.root}>
          {this.isChunkLoadError() ? (
            <ErrorState
              title="Update!"
              subtitle="This application has been updated, please refresh the page."
              action={updateAction}
            ></ErrorState>
          ) : (
            <ErrorState
              subtitle={this.getDefaultMessage()}
              action={this.errorAction}
            ></ErrorState>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

export default withStyles(useStyles)(ErrorBoundary);
